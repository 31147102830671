import {
  IconButton,
  Paper,
  TablePagination,
  TableSortLabel,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React from "react";

type Order = "asc" | "desc";

interface Column {
  id: "checkbox" | "member" | "menu" | "name" | "roles";
  label: string;
  minWidth?: number;
  align?: "left";
  format?: (value: number) => string;
  sortable: boolean;
  sort: (a: IAM, b: IAM) => number;
}

const columns: Column[] = [
  {
    id: "checkbox",
    label: "",
    sortable: false,
    sort: () => 1,
  },
  {
    id: "member",
    label: "Member",
    minWidth: 170,
    sortable: true,
    sort: (a: IAM, b: IAM) => a.member.localeCompare(b.member),
  },
  {
    id: "name",
    label: "Name",
    minWidth: 80,
    sortable: true,
    sort: (a: IAM, b: IAM) => a.name.localeCompare(b.name),
  },
  {
    id: "roles",
    label: "Role(s)",
    minWidth: 170,
    sortable: true,
    sort: (a: IAM, b: IAM) => {
      const aStr = a.roles.sort((s1, s2) => s1.localeCompare(s2)).join();
      const bStr = b.roles.sort((s1, s2) => s1.localeCompare(s2)).join();
      return aStr.localeCompare(bStr);
    },
  },
  {
    id: "menu",
    label: "",
    sortable: false,
    sort: () => 1,
  },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
    width: "100%",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  checkbox: {
    "&$checked": {
      color: "#00bceb",
    },
  },
  checked: {},
});

interface IAM {
  member: string;
  name: string;
  roles: string[];
}

export interface IamTableProps {
  data: IAM[];
}

export const IamTable: React.FC<IamTableProps> = ({ data }) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<Column["id"]>("member");
  const sortRoutine = columns.find((c) => c.id === orderBy)?.sort;
  const [selected, setSelected] = React.useState<string[]>([]);
  const isSelected = (member: string) => selected.indexOf(member) !== -1;

  if (!sortRoutine) {
    throw new Error("sortRoutine not found");
  }

  const handleRequestSort = (property: Column["id"]) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCheckboxClick = (name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="IAM table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.sortable ? (
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : "asc"}
                      onClick={() => handleRequestSort(column.id)}
                    >
                      {column.label}
                      {orderBy === column.id ? (
                        <span className={classes.visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  ) : (
                    column.label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .sort((a: IAM, b: IAM) =>
                order === "asc" ? sortRoutine(a, b) : -sortRoutine(a, b)
              )
              .map((row) => {
                const isItemSelected = isSelected(row.member);
                return (
                  <TableRow key={row.member}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{ "aria-labelledby": "" }}
                        onClick={() => handleCheckboxClick(row.member)}
                        classes={{
                          root: classes.checkbox,
                          checked: classes.checked,
                        }}
                      />
                    </TableCell>
                    <TableCell scope="row">{row.member}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.roles.join(", ")}</TableCell>
                    <TableCell align="right">
                      <IconButton aria-label="actions">
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default IamTable;
