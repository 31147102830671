import { Box, Grid, Typography } from "@material-ui/core";
import PolicyIcon from "@material-ui/icons/Policy";
import React from "react";
import { useTranslation } from "react-i18next";
import Iam from "../components/iam/Iam";
import Layout from "../components/layout/Layout";
import SEO from "../components/seo/SEO";
import { iamTableMockData } from "../components/tables/iam-table/iam-mock-data";

const IamPage = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <SEO title={t("iam.title")} />
      <Box p={2}>
        <Box mb={2}>
          <Grid container direction={"row"} spacing={1}>
            <Grid item>
              <PolicyIcon />
            </Grid>
            <Grid>
              <Typography variant={"h5"}>{t("iam.title")}</Typography>
            </Grid>
          </Grid>
        </Box>
        <Iam data={iamTableMockData} maxWidth={1500} />
      </Box>
    </Layout>
  );
};

export default IamPage;
