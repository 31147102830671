import React from "react";
import {
  ContainerCard,
  ContainerCardProps,
} from "../surfaces/visualization/ContainerCard";
import { IamTable, IamTableProps } from "../tables/iam-table/IamTable";

export interface IamProps extends ContainerCardProps, IamTableProps {}

export const Iam: React.FC<IamProps> = (props) => {
  const { data, ...containerProps } = props;

  return (
    <ContainerCard innerProps={{ p: 0 }} {...containerProps}>
      <IamTable data={data} />
    </ContainerCard>
  );
};

export default Iam;
